(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('SbfTimeSlotDeleteController',SbfTimeSlotDeleteController);

    SbfTimeSlotDeleteController.$inject = ['$uibModalInstance', 'entity', 'SbfTimeSlot'];

    function SbfTimeSlotDeleteController($uibModalInstance, entity, SbfTimeSlot) {
        var vm = this;

        vm.sbfTimeSlot = entity;
        vm.nickname = vm.sbfTimeSlot.instructor.nickname ? " \"" + vm.sbfTimeSlot.instructor.nickname + "\"" : "";
        vm.sbfTimeSlot.amount = 1;
        vm.sbfTimeSlot.freeParticipants = false;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SbfTimeSlot.delete({
                    id: id,
                    amount: vm.sbfTimeSlot.amount,
                    freeParticipants: vm.sbfTimeSlot.freeParticipants
                },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
