(function() {
    'use strict';

    angular
        .module('askApp')
        .factory('DinghyBookingSearch', DinghyBookingSearch);

    DinghyBookingSearch.$inject = ['$resource'];

    function DinghyBookingSearch($resource) {
        var resourceUrl =  'api/_search/dinghy-bookings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
