(function() {
    'use strict';
    angular
        .module('askApp')
        .factory('Profile', Profile);

    Profile.$inject = ['$resource', 'DateUtils'];

    function Profile ($resource, DateUtils) {
        var resourceUrl =  'api/profiles/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.birthdate = DateUtils.convertLocalDateFromServer(data.birthdate);
                        data.expelledDate = DateUtils.convertLocalDateFromServer(data.expelledDate);
                        data.matriculationDate = DateUtils.convertLocalDateFromServer(data.matriculationDate);
                        data.idExpirationDate = DateUtils.convertLocalDateFromServer(data.idExpirationDate);
                        data.lastLogin = DateUtils.convertLocalDateFromServer(data.lastLogin);
                        data.fullMemberDate = DateUtils.convertLocalDateFromServer(data.fullMemberDate);
                        data.isafDate = DateUtils.convertLocalDateFromServer(data.isafDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthdate = DateUtils.convertLocalDateToServer(copy.birthdate);
                    copy.expelledDate = DateUtils.convertLocalDateToServer(copy.expelledDate);
                    copy.matriculationDate = DateUtils.convertLocalDateToServer(copy.matriculationDate);
                    copy.idExpirationDate = DateUtils.convertLocalDateToServer(copy.idExpirationDate);
                    copy.lastLogin = DateUtils.convertLocalDateToServer(copy.lastLogin);
                    copy.fullMemberDate = DateUtils.convertLocalDateToServer(copy.fullMemberDate);
                    copy.isafDate = DateUtils.convertLocalDateToServer(copy.isafDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthdate = DateUtils.convertLocalDateToServer(copy.birthdate);
                    copy.expelledDate = DateUtils.convertLocalDateToServer(copy.expelledDate);
                    copy.matriculationDate = DateUtils.convertLocalDateToServer(copy.matriculationDate);
                    copy.idExpirationDate = DateUtils.convertLocalDateToServer(copy.idExpirationDate);
                    copy.lastLogin = DateUtils.convertLocalDateToServer(copy.lastLogin);
                    copy.fullMemberDate = DateUtils.convertLocalDateToServer(copy.fullMemberDate);
                    copy.isafDate = DateUtils.convertLocalDateToServer(copy.isafDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
