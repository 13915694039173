(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('SbfTimeSlotController', SbfTimeSlotController);

    SbfTimeSlotController.$inject = ['$state', 'SbfTimeSlot', 'SbfTimeSlotSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams', 'entity', 'DateUtils'];

    function SbfTimeSlotController($state, SbfTimeSlot, SbfTimeSlotSearch, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams, entity, DateUtils) {

        var vm = this;

        vm.project = entity;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                SbfTimeSlotSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    projectID: $stateParams.id
                }, onSuccess, onError);
            } else {
                SbfTimeSlot.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    projectID: $stateParams.id
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'fromTime') {
                    result.push('fromTime');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.sbfTimeSlots = data;
                vm.page = pagingParams.page;

                for(var i = 0; i < vm.sbfTimeSlots.length; i++) {
                    vm.sbfTimeSlots[i].fromTime = DateUtils.convertDateTimeFromServer(vm.sbfTimeSlots[i].fromTime);
                    vm.sbfTimeSlots[i].toTime = DateUtils.convertDateTimeFromServer(vm.sbfTimeSlots[i].toTime);
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                id: $stateParams.id
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'fromTime';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
