(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('DinghyBookingDialogController', DinghyBookingDialogController);

    DinghyBookingDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'DinghyBooking', 'DinghyEvent', 'Profile', 'Dept', 'Dinghy'];

    function DinghyBookingDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, DinghyBooking, DinghyEvent, Profile, Dept, Dinghy) {
        var vm = this;

        vm.dinghyBooking = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.dinghyevents = DinghyEvent.query();
        vm.profiles = Profile.query();
        vm.depts = Dept.query({filter: 'dinghybooking-is-null'});
        $q.all([vm.dinghyBooking.$promise, vm.depts.$promise]).then(function() {
            if (!vm.dinghyBooking.dept || !vm.dinghyBooking.dept.id) {
                return $q.reject();
            }
            return Dept.get({id : vm.dinghyBooking.dept.id}).$promise;
        }).then(function(dept) {
            vm.depts.push(dept);
        });
        vm.dinghies = Dinghy.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.dinghyBooking.id !== null) {
                DinghyBooking.update(vm.dinghyBooking, onSaveSuccess, onSaveError);
            } else {
                DinghyBooking.save(vm.dinghyBooking, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('askApp:dinghyBookingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.bookingDate = false;
        vm.datePickerOpenStatus.validationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
