(function() {
    'use strict';

    angular
        .module('askApp')
        .factory('DinghyEventSearch', DinghyEventSearch);

    DinghyEventSearch.$inject = ['$resource'];

    function DinghyEventSearch($resource) {
        var resourceUrl =  'api/_search/dinghy-events/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
