(function() {
    'use strict';
    angular
        .module('askApp')
        .factory('DinghyEvent', DinghyEvent);

    DinghyEvent.$inject = ['$resource', 'DateUtils'];

    function DinghyEvent ($resource, DateUtils) {
        var resourceUrl =  'api/dinghy-events/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertDateTimeFromServer(data.fromDate);
                        data.untilDate = DateUtils.convertDateTimeFromServer(data.untilDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
