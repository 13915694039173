(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('MembershipFeeDetailController', MembershipFeeDetailController);

    MembershipFeeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MembershipFee', 'Profile', 'Dept'];

    function MembershipFeeDetailController($scope, $rootScope, $stateParams, previousState, entity, MembershipFee, Profile, Dept) {
        var vm = this;

        vm.membershipFee = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('askApp:membershipFeeUpdate', function(event, result) {
            vm.membershipFee = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
