(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('DinghyBookingDetailController', DinghyBookingDetailController);

    DinghyBookingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DinghyBooking', 'DinghyEvent', 'Profile', 'Dept', 'Dinghy'];

    function DinghyBookingDetailController($scope, $rootScope, $stateParams, previousState, entity, DinghyBooking, DinghyEvent, Profile, Dept, Dinghy) {
        var vm = this;

        vm.dinghyBooking = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('askApp:dinghyBookingUpdate', function(event, result) {
            vm.dinghyBooking = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
