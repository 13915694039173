(function() {
    'use strict';

    angular
        .module('askApp')
        .controller('DinghyDetailController', DinghyDetailController);

    DinghyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Dinghy'];

    function DinghyDetailController($scope, $rootScope, $stateParams, previousState, entity, Dinghy) {
        var vm = this;

        vm.dinghy = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('askApp:dinghyUpdate', function(event, result) {
            vm.dinghy = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
