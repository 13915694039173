(function() {
    'use strict';

    angular
        .module('askApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('profile', {
            parent: 'entity',
            url: '/profile?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'askApp.profile.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/profile/profiles.html',
                    controller: 'ProfileController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('profile');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumClothingSize');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumGender');
                    $translatePartialLoader.addPart('enumMemberStatus');
                    $translatePartialLoader.addPart('enumIDType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('profile-detail', {
            parent: 'profile',
            url: '/profile/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'askApp.profile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/profile/profile-detail.html',
                    controller: 'ProfileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('profile');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumClothingSize');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumCountry');
                    $translatePartialLoader.addPart('enumGender');
                    $translatePartialLoader.addPart('enumMemberStatus');
                    $translatePartialLoader.addPart('enumIDType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Profile', function($stateParams, Profile) {
                    return Profile.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'profile',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('profile-detail.edit', {
            parent: 'profile-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-dialog.html',
                    controller: 'ProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Profile', function(Profile) {
                            return Profile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('profile.new', {
            parent: 'profile',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-dialog.html',
                    controller: 'ProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nickname: null,
                                street: null,
                                city: null,
                                country: null,
                                postcode: null,
                                weight: null,
                                clothingSize: null,
                                shoeSize: null,
                                nationality: null,
                                birthplace: null,
                                countryOfBirth: null,
                                phone: null,
                                gender: null,
                                birthdate: null,
                                isStudent: null,
                                isVerified: null,
                                memberStatus: null,
                                expelledDate: null,
                                university: null,
                                matriculationCertificate: null,
                                matriculationCertificateContentType: null,
                                matriculationDate: null,
                                isUsingSepa: null,
                                bankAccountHolder: null,
                                iban: null,
                                bic: null,
                                isDeviatedHolder: null,
                                emailBackup: null,
                                idNumber: null,
                                idExpirationDate: null,
                                idType: null,
                                lastLogin: null,
                                fullMemberDate: null,
                                commentBoard: null,
                                commentSkipper: null,
                                wantsMembershipTermination: null,
                                wantsNewsletter: null,
                                sbfBinnen: null,
                                sbfSee: null,
                                sks: null,
                                sss: null,
                                shs: null,
                                ubi: null,
                                src: null,
                                lrc: null,
                                bsp: null,
                                fkn: null,
                                isaf: null,
                                isafDate: null,
                                image: null,
                                imageContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('profile', null, { reload: 'profile' });
                }, function() {
                    $state.go('profile');
                });
            }]
        })
        .state('profile.edit', {
            parent: 'profile',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-dialog.html',
                    controller: 'ProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Profile', function(Profile) {
                            return Profile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('profile', null, { reload: 'profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('profile.delete', {
            parent: 'profile',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-delete-dialog.html',
                    controller: 'ProfileDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Profile', function(Profile) {
                            return Profile.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('profile', null, { reload: 'profile' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
